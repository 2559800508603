import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import SEO from "../../../components/seo"
import PageWrapper from "../../../components/PageWrapper"
import { Container, Row, Col } from "react-bootstrap"
import { useContent } from "../../../hooks/useContent"
import NoAuthRedirect from "../../../components/Core/NoAuthRedirect"
import PageTitle from "../../../components/Core/PageTitle"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import useAccount from "../../../hooks/useAccount"
import QuickFeedback from "../../../components/QuickFeedback"
import SectionSeparator from "../../../components/Core/SectionSeparator"

const Content = styled.div`

    & h2 {
        line-height: 1 !important;
    }

`

const headerConfig = {
    paddingBottom: 10,
    variant: 'minimal'
};

const InfoReferral = () => {
    
    const content = useContent()
    const account = useAccount()
    
    return (
        <>
            <UnauthenticatedTemplate>
                <NoAuthRedirect />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageWrapper headerConfig={headerConfig}>
                    <SEO title={content.get('what-you-need-to-know-referral-page-title', true)} />
                    <Content>
                        <Container>
                            <Breadcrumb className="mt-20">
                                <Breadcrumb.Item active><Link to="/">Home</Link></Breadcrumb.Item>
                                <Breadcrumb.Item href="/what-you-need-to-know">
                                    <Link to="/what-you-need-to-know">{content.get('what-you-need-to-know-page-title', true)}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {content.get('what-you-need-to-know-referral-page-title', true)}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Container>
                        <PageTitle 
                            align="left"
                            title={content.get('what-you-need-to-know-referral-page-title', true)}
                        />
                        <div className="inner-content">
                            <Container>
                                <Row>
                                    <Col className="col-12 col-md-6">
                                        {content.get(`section-referral-intro-${account.entity}`, false)}
                                    </Col>
                                </Row>
                            </Container>
                            <SectionSeparator fullWidth={true} />
                            <Container className="py-14">
                                <Row className="justify-content-center">
                                    <h3 className="font-family-primary font-size-5 text-center mb-8">Did you find the answer to your question?</h3>
                                </Row>
                                <Row className="justify-content-center">
                                    <QuickFeedback category="found-answer-to-question" />
                                </Row>
                            </Container>
                        </div>
                    </Content>
                </PageWrapper>
            </AuthenticatedTemplate>
        </>
    )
}

export default InfoReferral