import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";


const StyledSeparator = styled(Container)`
    border-bottom: thin solid #E6E6E6;
`

const SectionSeparator = ({fullWidth=true}) => {

    return (<>
            <StyledSeparator fluid={fullWidth ? true : false}>
            </StyledSeparator>
        </>
    )
}

export default SectionSeparator